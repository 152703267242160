import { useState, useEffect } from 'react'
import type { StructType } from '../lib/structures'

interface BBox {
  west: number
  south: number
  east: number
  north: number
}

interface BBoxResult {
  loading: boolean
  bbox?: BBox
}

export function useStructureBbox (type: StructType, code: string): BBoxResult {
  const [loading, setLoading] = useState<boolean>(true)
  const [bbox, setBbox] = useState<BBox | undefined>()

  useEffect(() => {
    let cancelled = false

    void (async () => {
      setLoading(true)

      if (type === 'country') {
        setBbox({ west: 16, south: -35, east: 33, north: -22 })
        setLoading(false)
        return
      }

      const response = await fetch(`https://gis.da-io.net/current/${type}/${code}/bbox`)

      if (cancelled) return
      if (response.status === 404) {
        setBbox(undefined)
        setLoading(false)
        return
      } else if (!response.ok) {
        throw new Error(`Fetch error: ${response.statusText}`)
      }

      const result = await response.json() as number[]
      if (cancelled) return
      setBbox({
        west: result[0],
        south: result[1],
        east: result[2],
        north: result[3]
      })
      setLoading(false)
    })()

    return () => { cancelled = true }
  }, [type, code])

  if (loading) {
    return { loading }
  } else {
    return {
      loading,
      bbox
    }
  }
}
