import ResponsiblePersons from './ResponsiblePersons'
import { StructureBase, structConf } from '../lib/structures'
import { firstCap } from '../lib/utils'

interface PageHeaderProps {
  structure: StructureBase
}

export default function PageHeader ({ structure }: PageHeaderProps): JSX.Element {
  return (
    <h2>
      {structure.name}{' '}
      <small className='text-muted'>({firstCap(structConf[structure.type].displayTitle)} {structure.code}) <ResponsiblePersons persons={structure.responsiblePersons} removeNewLine /></small>
    </h2>
  )
}
