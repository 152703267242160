import { isStructType, StructParams } from '../lib/structures'
import { useParams } from 'react-router-dom'

export default function useStructureParams (): StructParams {
  // get parameters from the URL
  const { code, type } = useParams()

  // make sure the paramters are acceptable
  if (code == null || type == null) {
    throw new Error('useStructureParams called without needed parameters in route')
  }
  if (!isStructType(type)) {
    throw new Error(`Invalid structure type ${type}`)
  }

  return { type, code }
}
