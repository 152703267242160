import './Map.css'
import { useState, useEffect } from 'react'
import { Map, TileLayer, withLeaflet } from 'react-leaflet'
import OriginalVectorGrid from 'react-leaflet-vectorgrid'

import { useStructureBbox } from '../data/useStructureBbox'
import { StructType, StructureBase, structConf } from '../lib/structures'
import { LoadingSpinner } from './spinners'
import type { LatLngBoundsLiteral } from 'leaflet'

const VectorGrid = withLeaflet(OriginalVectorGrid)

interface MapProps {
  type: StructType
  code: string
  childStructures: StructureBase[]
  childColourFn?: (code: string) => string
  childTypeOverride?: StructType
}

export default function ProfileMap (props: MapProps): JSX.Element {
  const {
    type,
    code,
    childStructures,
    childColourFn = () => '#b00000',
    childTypeOverride
  } = props
  const { loading, bbox } = useStructureBbox(type, code)
  const [nonce, setNonce] = useState<number>(Math.random())

  useEffect(() => {
    setNonce(Math.random())
  }, [childColourFn])

  let parentLayer = null
  if (type !== 'country') {
    parentLayer = {
      type: 'protobuf',
      url: `https://tileserver.da-io.net/${type}/{z}/{x}/{y}/tile.mvt`,
      maxZoom: 16,
      vectorTileLayerStyles: {
        [type]: (properties: { code: string }) => {
          return {
            stroke: properties.code === code,
            weight: 3,
            color: '#646464',
            fill: false
          }
        }
      },
      zIndex: 402,
      subdomains: 'abc'
    }
  }

  const childType = childTypeOverride ?? ((type === 'province' && (code === 'WC' || code === 'GP')) ? 'region' : structConf[type].mapChildren)
  let childLayer = {}
  if (childType != null) {
    const childLayerName = childType === 'votingdistrict' ? 'vdistrict' : childType
    const childCodes = childStructures.map(c => c.code)
    childLayer = {
      type: 'protobuf',
      url: `https://tileserver.da-io.net/${childLayerName}/{z}/{x}/{y}/tile.mvt`,
      maxZoom: 16,
      vectorTileLayerStyles: {
        [childLayerName]: (properties: { code: string }) => {
          const show = childCodes.includes(properties.code)
          return {
            stroke: show,
            weight: 0.5,
            color: '#646464',
            fill: show,
            fillColor: childColourFn(properties.code),
            fillOpacity: 0.5
          }
        }
      },
      zIndex: 401,
      subdomains: 'abc',
      interactive: true
    }
  }

  if (loading) {
    return (
      <div className='profile-map'>
        <LoadingSpinner />
      </div>
    )
  } else {
    const bounds = [
      [bbox?.south ?? 0, bbox?.west ?? 0],
      [bbox?.north ?? 0, bbox?.east ?? 0]
    ] as LatLngBoundsLiteral
    return (
      <div className='profile-map'>
        <Map scrollWheelZoom={false} bounds={bounds}>
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {parentLayer != null ? <VectorGrid {...parentLayer} /> : <></>}
          {childLayer != null ? <VectorGrid key={nonce} {...childLayer} /> : <></>}
        </Map>
      </div>
    )
  }
}
