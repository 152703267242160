import { gql, useQuery } from '@apollo/client'

const query = gql`
query getUserData {
  userInfo {
    darn surname firstname position
  }
}
`
export interface Person {
  firstname: String
  surname: String
  displayname: String
}

interface UserInfoData {
  userInfo: Person
}

export default function useUserInfo (): Person | undefined {
  const { error, data } = useQuery<UserInfoData>(query)
  if (error != null) throw error
  return data?.userInfo
}
