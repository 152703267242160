import React from 'react'

import { ErrorMessage, ForbiddenMessage } from './alerts'

export class ForbiddenError extends Error {}

interface ErrorState {
  error: Error | null
}

class ErrorBoundary extends React.Component {
  state: ErrorState = { error: null }

  static getDerivedStateFromError (error: Error): ErrorState {
    return { error }
  }

  render (): React.ReactNode {
    const { error } = this.state
    if (error != null) {
      return <ErrorDisplay error={error} />
    } else {
      return this.props.children
    }
  }
}

export function ErrorDisplay ({ error }: { error: any }): JSX.Element {
  if (error instanceof ForbiddenError) {
    return <ForbiddenMessage forbiddenItem={error.message} />
  } else {
    return <ErrorMessage message={error.message} />
  }
}

export default ErrorBoundary
