import React from 'react'
import { Helmet } from 'react-helmet-async'
import ListGroup from 'react-bootstrap/ListGroup'
import { LinkContainer } from 'react-router-bootstrap'
import { UnassignedStructureItem } from './UnassignedStructureItem'

import { LoadingSpinner } from './spinners'
import { compareStrings, firstCap } from '../lib/utils'
import { structConf } from '../lib/structures'
import useUserStructures from '../data/useUserStructures'

const sortKey: Record<string, number> = {
  province: 1,
  region: 2,
  constituency: 3,
  municipality: 4,
  ward: 5,
  votingdistrict: 6
}

export default function IndexView (): JSX.Element {
  const structures = useUserStructures()

  if (structures == null) {
    return <LoadingSpinner />
  }

  // copy because Apollo may hand us an Immutable
  const sortedStructures = [...structures]

  sortedStructures.sort((a, b) => {
    if (a.type === b.type) {
      return compareStrings(a.name, b.name)
    } else {
      return sortKey[a.type] - sortKey[b.type]
    }
  })

  return (
    <>
      <Helmet>
        <title>Campaign 2024 Dashboard</title>
      </Helmet>

      <h2>Campaign 2024 Dashboard</h2>

      <ListGroup>
        {sortedStructures.map(({ type, code, name, structureHasAssignedWards }) => {
          const title = firstCap(structConf[type].displayTitle)
          return (structureHasAssignedWards)
            ? (
            <LinkContainer key={`${type}:${code}`} to={`/${type}/${code}`}>
              <ListGroup.Item action>
                <span className='text-muted'>{title}:</span>{' '}
                {name}{' '}
                <span className='text-muted'>({code})</span>
              </ListGroup.Item>
            </LinkContainer>
          )
            : <UnassignedStructureItem type={type} code={code} name={name} title={title} />
        })}
      </ListGroup>
    </>
  )
}
