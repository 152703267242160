import React from 'react'
import Alert from 'react-bootstrap/Alert'

import { StructType } from '../lib/structures'

interface ErrorMessageProps {
  message: string
}

export const ErrorMessage = ({ message }: ErrorMessageProps): JSX.Element => (
  <Alert variant='danger'>
    <Alert.Heading>Unfortunately an error has occurred.</Alert.Heading>

    <b>Please check your internet connection and try refreshing this page.</b>

    <hr />

    <p>If errors continue to occur please contact <a href='mailto:support@da.org.za'>support@da.org.za</a> for assistance with the following technical details:</p>

    <blockquote><pre>{message}</pre></blockquote>
  </Alert>
)

interface ForbiddenMessageProps {
  forbiddenItem: string
}

export const ForbiddenMessage = ({ forbiddenItem }: ForbiddenMessageProps): JSX.Element => (
  <Alert variant='danger'>
    You are not allowed access to {forbiddenItem}. For assistance please contact <a href='mailto:support@da.org.za'>support@da.org.za</a>.
  </Alert>
)

export const NoAssignedWardsMessage = ({ type }: { type: StructType }): JSX.Element => (
  <Alert variant="warning" >
    <Alert.Heading>{(type === 'ward') ? 'Ward' : 'Constituency'} is not part of My DA My Branch</Alert.Heading>
    <div>
      <p>
        {(type === 'ward')
          ? ' The current Ward does not form part of My DA, My Branch Programme of Action '
          : 'The current Constituency does not contain any ward that forms part of My DA, My Branch Programme of Action'}
      </p>
      <p>
        For a ward to be measured it must either have:
        <ul>
          <li>an elected DA Ward Councilor, or</li>
          <li>a PR councilor assigned to it in the Public Rep System,
            <br />
            <b>AND</b>
            <br /> received 250 or more DA Ward votes in the previous Local Government Election.
          </li>
          <li>an audited established branch</li>

        </ul>
      </p>
      <hr />
      <p className="mb-0">
        Please contact your "My DA, My Branch" Provincial Staff Champion, via your Provincial Office,
        <br />
        if you have any questions or queries relating to the MDMB Dashboard or Activity App
      </p>
    </div>
  </Alert>
)

