import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'

import { LoadingSpinner } from './spinners'
import IndexView from './IndexView'
import SummaryView from './SummaryView'
import RegistrationView from './RegistrationView'
import CanvassingView from './CanvassingView'

const AppRoutes = (): JSX.Element => (
  <Suspense fallback={<LoadingSpinner />}>
    <Routes>
      <Route path='/' element={<IndexView />} />
      <Route path='/:type/:code' element={<SummaryView />} />
      <Route path='/:type/:code/registration' element={<RegistrationView />} />
      <Route path='/:type/:code/canvassing' element={<CanvassingView />} />
    </Routes>
  </Suspense>
)

export default AppRoutes
