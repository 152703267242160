import { gql, useQuery } from '@apollo/client'
import { ForbiddenError } from '../components/ErrorBoundary'
import { StructParams, StructureBase, getChildType, QueryStructParams } from '../lib/structures'

export interface StructureSummary extends StructureWithSummaryData {
  parents: StructureBase[]
  children: StructureWithSummaryData[]
}

interface StructureWithSummaryData extends StructureBase {
  registration: {
    rollAll: number
    rollSheet: number
    milestone: number
    target: number
  }
  canvassing: {
    current: number
    milestone: number
    target: number
  }
}

interface SummaryResult {
  structure: StructureSummary
}

const query = gql`
query getSummaryData($type: String!, $code: String!, $childType: String!) {
  structure(type: $type, code: $code) {
    type
    code
    name
    responsiblePersons { firstname surname displayname }
    userHasAccess
    structureHasAssignedWards
    registration {
      rollAll
      rollSheet
      milestone
      target
    }
    canvassing {
      current
      milestone
      target
    }
    parents { type code name userHasAccess }
    children(type: $childType) {
      type
      code
      name
    responsiblePersons { firstname surname displayname }
      userHasAccess
      structureHasAssignedWards
    registration {
      rollAll
      rollSheet
      milestone
      target
    }
    canvassing {
      current
      milestone
      target
    }
    }
  }
}
`

export default function useSummaryData ({ type, code }: StructParams): StructureSummary | undefined {
  const childType = getChildType({ type, code })
  const { error, data } = useQuery<SummaryResult, QueryStructParams>(
    query, { variables: { type, code, childType } }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return data?.structure
}
