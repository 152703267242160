import React, { useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button'
import { NoAssignedWardsMessage } from './alerts'
import { StructType } from '../lib/structures'

interface UnassignedStructureItemProps {
  type: StructType
  code: string
  name: string
  title: string
}

export function UnassignedStructureItem ({ type, code, name, title }: UnassignedStructureItemProps): JSX.Element {
  const [open, setOpen] = useState(false)

  return (
    < ListGroup.Item variant='warning' >
      <span className='text-muted'>{title}:</span>{' '}
      {name} {' '}
      <span className='text-muted'>({code})</span>{' '}
      <span>&mdash; <b>Not part of My DA, My Branch</b> <Button variant='link' onClick={() => setOpen(!open)}>(More info)</Button></span>
      <Collapse in={open}>
        <div>
          <NoAssignedWardsMessage type={type} />
        </div>
      </Collapse>
    </ListGroup.Item >
  )
}