import React from 'react'

import { Link } from 'react-router-dom'
import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, wardNamesCleanup } from '../lib/structures'
import { useCanvassing } from '../data/useCanvassing'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import ResponsiblePersons from './ResponsiblePersons'

import Table from 'react-bootstrap/Table'
import { Col, Row } from 'react-bootstrap'

import Map from './Map'

import { LoadingSpinner } from './spinners'
import { NoAssignedWardsMessage } from './alerts'
import { statsStyle, formatInt, formatPerc } from '../lib/utils'

import {
  Chart as ChartJS,
  registerables
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation'

import { Line } from 'react-chartjs-2'

ChartJS.register(annotationPlugin, ...registerables)

export default function CanvassingView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = useCanvassing({ type, code })

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)
    .sort((a, b) => {
      if (b.canvassing.milestone === 0 && a.canvassing.milestone === 0) return b.canvassing.current - a.canvassing.current
      else if (b.canvassing.milestone === 0) return -1
      else if (a.canvassing.milestone === 0) return 1
      else return (b.canvassing.current / b.canvassing.milestone) - (a.canvassing.current / a.canvassing.milestone)
    })

  const mapColor = (code: string): string => {
    const c = children.find(c => c.code === code)
    if (c == null) return '#ffffff'
    const color = statsStyle(c.canvassing.current, c.canvassing.milestone).backgroundColor
    return color
  }

  const historicLine = stats.canvassing.historic.map(datum => ({ x: datum.date, y: datum.count }))
  const milestonesLine = stats.canvassing.milestones.map(datum => ({ x: datum.date, y: datum.count }))

  console.log(milestonesLine)

  const graphData = {
    datasets: [
      {
        label: 'Canvassing',
        data: historicLine,
        borderColor: '#4D77FF'
      },
      {
        label: 'Canvassing Worm',
        data: milestonesLine,
        borderColor: '#56BBF1',
        borderDash: [2, 2],
        pointRadius: 0
      }
    ]
  }

  const graphOptions = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'd LLL yyyy'
        }
      },
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      autocolors: false,
      annotation: {
        annotations: {
          target: {
            type: 'line',
            yMin: stats.canvassing.target,
            yMax: stats.canvassing.target,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            label: {
              enabled: true,
              content: 'Target'
            }
          }
        }
      }
    }
  }

  if (!stats.structureHasAssignedWards) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/canvassing' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='canvassing' />

        <NoAssignedWardsMessage type={stats.type} />
      </>
    )
  } else {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/canvassing' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='canvassing' />

        <Row>
          <Col md={6}>
            <div style={{ position: 'relative', height: '400px', width: '100%' }}>
              {/* @ts-expect-error */}
              <Line options={graphOptions} data={graphData} />

            </div>
          </Col>
          <Col md={6}>
            <Map type={type} code={code} childStructures={stats.children} childColourFn={mapColor} />
            <b>Canvasses as a % of where we should be</b>
          </Col>
        </Row>

        <Table responsive striped className='break-before stats'>
          <thead>
            <tr>
              {stats.type !== 'ward' ? <th /> : null}
              <th className='text-center'>Current Unique Affiliation Canvasses <br />(since 1 Nov 2021)</th>
              <th className='text-center'>Canvasses we should have done by now </th>
              <th className='text-center'>Canvasses as a % of where we should be</th>
              <th className='text-center'>Unique Affiliation Canvasses Target by May 2024</th>
              <th className='text-center'>Canvasses as a % of Unique Affiliation Canvasses Target</th>
            </tr>
          </thead>
          <tbody>
            {children.map(c => (
              <tr key={c.code}>
                {stats.type !== 'ward' ? <td><Link to={`/${c.type}/${c.code}/canvassing`}>{c.name}</Link> <ResponsiblePersons persons={c.responsiblePersons} /></td> : null}
                <td className='text-center'>{formatInt(c.canvassing.current)}</td>
                <td className='text-center'>{formatInt(c.canvassing.milestone)}</td>
                <td style={statsStyle(c.canvassing.current, c.canvassing.milestone)} className='text-center'>{formatPerc(c.canvassing.current / c.canvassing.milestone)}</td>
                <td className='text-center'>{formatInt(c.canvassing.target)}</td>
                <td style={statsStyle(c.canvassing.current, c.canvassing.target)} className='text-center'>{formatPerc(c.canvassing.current / c.canvassing.target)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className='font-weight-bold'>
              {stats.type !== 'ward' ? <td>Total</td> : null}
              <td className='text-center'>{formatInt(stats.canvassing.current)}</td>
              <td className='text-center'>{formatInt(stats.canvassing.milestone)}</td>
              <td style={statsStyle(stats.canvassing.current, stats.canvassing.milestone)} className='text-center'>{formatPerc(stats.canvassing.current / stats.canvassing.milestone)}</td>
              <td className='text-center'>{formatInt(stats.canvassing.target)}</td>
              <td style={statsStyle(stats.canvassing.current, stats.canvassing.target)} className='text-center'>{formatPerc(stats.canvassing.current / stats.canvassing.target)}</td>
            </tr>
          </tfoot>
        </Table>
      </>
    )
  }
}
