import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { LinkContainer } from 'react-router-bootstrap'
import type { StructureWithParents } from '../lib/structures'

interface BreadcrumbsProps {
  structure: StructureWithParents
  suffix?: string
  admin?: boolean
}

export default function Breadcrumbs ({ structure, suffix, admin = false }: BreadcrumbsProps): JSX.Element {
  return (
    <Breadcrumb className='d-print-none'>
      {(structure.parents ?? []).map(({ type, code, name, userHasAccess, userHasAdminAccess }) => (admin && userHasAdminAccess) || (!admin && userHasAccess)
        ? (
          <LinkContainer key={type} to={`${admin ? '/admin' : ''}/${type}/${code}${suffix ?? ''}`}>
            <Breadcrumb.Item>{name}</Breadcrumb.Item>
          </LinkContainer>
          )
        : <Breadcrumb.Item active key={type}>{name}</Breadcrumb.Item>
      )}
      <Breadcrumb.Item active>{structure.name}</Breadcrumb.Item>
    </Breadcrumb>
  )
}
