import { gql, useQuery } from '@apollo/client'

interface Feature {
  name: string
  enabled: boolean
  description?: string
}

const query = gql`
query getFeature ($name: String!) {
  feature(name: $name) {
    name enabled description
  }
}
`

interface Vars {
  name: string
}

interface Data {
  feature?: Feature
}

interface UseFeatureReturns {
  loading: boolean
  feature?: Feature
}

export default function useFeature (name: string): UseFeatureReturns {
  const { error, data, loading } = useQuery<Data, Vars>(query, {
    variables: { name },
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 // we poll once a minute to ensure that we pick up any feature flag changes
  })
  if (error != null) throw error
  return {
    loading,
    feature: data?.feature
  }
}
