import React from 'react'
import Container from 'react-bootstrap/Container'
import MainNav from './MainNav'
import type { WithChildren } from '../lib/utils'

const Layout = ({ children }: WithChildren): JSX.Element => (
  <>
    <MainNav />
    <Container fluid className='my-3'>
      {children}
    </Container>
  </>
)

export default Layout
