import useFeature from '../data/useFeatureFlag'
import useUserStructures from '../data/useUserStructures'
import { WithChildren } from '../lib/utils'
import { LoadingSpinner } from './spinners'
import Alert from 'react-bootstrap/Alert'

export default function ProvinceLock ({ children }: WithChildren): JSX.Element {
  const userStructures = useUserStructures()
  const { loading, feature } = useFeature('province-lock')
  if (userStructures == null || loading) {
    return <LoadingSpinner />
  }

  // the feature is enabled AND the user does not have national georole
  if (feature?.enabled === true && !userStructures.some(({ type, code }) => type === 'country' && code === 'ZA')) {
    return (
      <Alert variant='warning'>{feature.description}</Alert>
    )
  } else {
    return <>{children}</>
  }
}
