import { Person } from '../lib/structures'

export default function ResponsiblePersons ({ persons, removeNewLine = false }: { persons: [Person], removeNewLine?: boolean }): JSX.Element {
  const shortDisplayNames = persons.map(p => p.displayname).join(', ')
  const longDisplayNames = persons.map(p => `${p.firstname} ${p.surname}`).join('\n')
  if (persons.length < 1) return (<></>)
  return (
    <>
      {removeNewLine ? '' : (<br />)}
      <span title={longDisplayNames}>({shortDisplayNames})</span>
    </>
  )
}
