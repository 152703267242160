import { gql, useQuery } from '@apollo/client'
import type { StructureBase } from '../lib/structures'

const query = gql`
query getAdminStructures {
 adminStructures {
    type code name
  }
}
`

interface Result {
  adminStructures: StructureBase[]
}

export default function useAdminStructures (): StructureBase[] | undefined {
  const { error, data } = useQuery<Result>(query)
  if (error != null) throw error
  return data?.adminStructures
}
