import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ApolloProvider as ActualApolloProvider
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { getLocalToken } from 'react-u5auth'
import type { WithChildren } from '../lib/utils'

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors != null) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations?.toString() ?? ''}, Path: ${path?.toString() ?? ''}`)
        )
      }
      if (networkError != null) console.log(`[Network error]: ${networkError.message}`)
    }),
    setContext((_, { headers }) => {
      const token = getLocalToken()
      return {
        headers: {
          ...headers,
          Authorization: (token != null) ? `Bearer ${token}` : ''
        }
      }
    }),
    new HttpLink({
      uri: process.env.REACT_APP_API_URL,
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Structure: {
        keyFields: ['type', 'code']
      },
      Query: {
        fields: {
          structure: {
            read: (_, { args, toReference }) => toReference({
              __typename: 'Structure',
              type: args?.type,
              code: args?.code
            })
          }
        }
      }
    }
  })
})

export const ApolloProvider = ({ children }: WithChildren): JSX.Element => (
  <ActualApolloProvider client={apolloClient}>
    {children}
  </ActualApolloProvider>
)
