import { gql, useQuery } from '@apollo/client'
import { ForbiddenError } from '../components/ErrorBoundary'
import { StructParams, StructureBase, getChildType, QueryStructParams } from '../lib/structures'
import type { Timeline } from '../types/Timeline'

interface RegistrationDataForChildren {
  rollAll: number
  rollSheet: number
  tags: {
    wrSheet: number
    astregSheet: number
    wrSheetRoll: number
    astregSheetRoll: number
  }
  milestone: number
  target: number
}

interface RegistrationDataForSelf extends RegistrationDataForChildren {
  historicRoll: Timeline<number>
  historicTags: Timeline<number>
  milestones: Timeline<number>
}

export interface StructureWithRegistration extends StructureWithRegistrationBase {
  registration: RegistrationDataForSelf
  parents: StructureBase[]
  children: StructureWithRegistrationBase[]
}

interface StructureWithRegistrationBase extends StructureBase {
  registration: RegistrationDataForChildren
}

interface RegistrationResult {
  structure: StructureWithRegistration
}

const query = gql`
query getRegistrationData($type: String!, $code: String!, $childType: String!) {
  structure(type: $type, code: $code) {
    type
    code
    name
    responsiblePersons { firstname surname displayname }
    userHasAccess
    structureHasAssignedWards
    registration {
        rollAll
        rollSheet
        tags {
          wrSheet
          astregSheet
          wrSheetRoll
          astregSheetRoll
        }
        milestone
        target
        historicRoll {
          date
          count
        }
        historicTags {
          date
          count
        }
        milestones {
          date
          count
        }
      }
    parents { type code name userHasAccess }
    children(type: $childType) {
      type
      code
      name
      responsiblePersons { firstname surname displayname }
      userHasAccess
      structureHasAssignedWards
      registration {
        rollAll
        rollSheet
        tags {
          wrSheet
          astregSheet
          wrSheetRoll
          astregSheetRoll
        }
        milestone
        target
      }
    }
  }
}
`

export function useRegistration ({ type, code }: StructParams): StructureWithRegistration | undefined {
  const childType = getChildType({ type, code })
  const { error, data } = useQuery<RegistrationResult, QueryStructParams>(
    query, { variables: { type, code, childType } }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return data?.structure
}
