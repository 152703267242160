import { compareStrings } from '../lib/utils'

export type StructType = 'country' | 'province' | 'region' | 'constituency' | 'municipality' | 'ward'

export function isStructType (type: string): type is StructType {
  return Object.prototype.hasOwnProperty.call(structConf, type)
}

export interface StructParams {
  type: StructType
  code: string
}

export interface QueryStructParams extends StructParams {
  childType: string
  includeWards?: boolean
}

export interface Person {
  firstname: string
  surname: string
  displayname: string
}

export interface StructureBase extends StructParams {
  name: string
  responsiblePersons: [Person]
  userHasAccess: boolean
  userHasAdminAccess: boolean
  structureHasAssignedWards: boolean
}

export interface StructureWithParents extends StructureBase {
  parents: StructureBase[]
}

interface StructConf {
  displayTitle: string
  children?: StructType
  mapChildren: string
}

export const structConf: Record<StructType, StructConf> = {
  country: {
    displayTitle: 'country',
    children: 'province',
    mapChildren: 'province'
  },
  province: {
    displayTitle: 'province',
    children: 'municipality',
    mapChildren: 'constituency'
  },
  region: {
    displayTitle: 'region',
    children: 'constituency',
    mapChildren: 'constituency'
  },
  constituency: {
    displayTitle: 'constituency',
    children: 'ward',
    mapChildren: 'ward'
  },
  municipality: {
    displayTitle: 'municipality',
    children: 'ward',
    mapChildren: 'ward'
  },
  ward: {
    displayTitle: 'ward',
    mapChildren: 'votingdistrict'
  }
}

export function filterAndSortChildren<T extends StructureBase> (type: StructType, children: T[]): T[] {
  let sortedChildren = [...children]
  if (type === 'province') {
    const hasRegions = children.some(c => c.type === 'region')
    sortedChildren = children.filter(c => c.type === (hasRegions ? 'region' : 'constituency'))
    sortedChildren.sort((a, b) => compareStrings(a.name, b.name))
  } else if (type === 'constituency' || type === 'municipality') {
    sortedChildren.sort((a, b) => compareStrings(a.code, b.code))
  } else {
    sortedChildren.sort((a, b) => compareStrings(a.name, b.name))
  }
  return sortedChildren
}


export function getChildType ({ type, code }: StructParams): string {
  return (type === 'province' && (code === 'WC' || code === 'GP')) ? 'region' : structConf[type].mapChildren
}

export function hasParent (structure: StructureWithParents, { type, code }: StructParams): boolean {
  return (structure.type === type && structure.code === code) || structure?.parents?.some(p => p.type === type && p.code === code)
}

export function inProvince (structure: StructureBase, provinceCode: string): boolean {
  return hasParent(structure as StructureWithParents, { type: 'province', code: provinceCode })
}

export function wardNamesCleanup<T extends StructureBase> (child: T, index: number, children: T[]): T {
  if (!children.every(x => x.type === 'ward')) {
    return child
  }

  const wardNamePrefixs = children.map(c => {
    const matchArray = c.name.match(/(.+)Ward.+/)
    return matchArray != null ? matchArray[1] : ''
  })

  return wardNamePrefixs.every(n => n === wardNamePrefixs[0])
    ? (
        {
          ...child,
          name: child.name.replace(wardNamePrefixs[0], '')
        }
      )
    : child
}
