import React from 'react'
import { AuthContext, useAuthentication } from 'react-u5auth'
import { LoggingInSpinner } from '../components/spinners'
import type { WithChildren } from '../lib/utils'

const clientId = process.env.REACT_APP_AUTH_CLIENT_ID ?? 'auth_client_id_unset'
const authProvider = process.env.REACT_APP_AUTH_PROVIDER ?? 'https://login.voteda.org'

export const AuthProvider = ({ children }: WithChildren): JSX.Element => (
  <AuthContext clientId={clientId} provider={authProvider}>
    {children}
  </AuthContext>
)

export const Authenticated = ({ children }: WithChildren): JSX.Element => {
  const { authenticated } = useAuthentication()
  return authenticated ? <>{children}</> : <LoggingInSpinner />
}
