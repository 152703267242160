import React from 'react'

import { Link } from 'react-router-dom'
import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, wardNamesCleanup } from '../lib/structures'
import useSummaryData from '../data/useSummaryData'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import { LoadingSpinner } from './spinners'
import { NoAssignedWardsMessage } from './alerts'
import ResponsiblePersons from './ResponsiblePersons'
import { statsStyle, formatPerc, statsClass } from '../lib/utils'

import Table from 'react-bootstrap/Table'

export default function SummaryView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = useSummaryData({ type, code })

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)

  if (!stats.structureHasAssignedWards) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='summary' />

        <NoAssignedWardsMessage type={stats.type} />
      </>
    )
  } else {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='summary' />

        <Table responsive striped bordered className='stats'>
          <thead>
            <tr>
              {stats.type !== 'ward' ? <th /> : null}
              <th className='text-center'>All Registered DA Voters confirmed as registered correctly, per IEC</th>
              <th className='text-center'>DA Voters Registered correctly from Sheets, per IEC</th>
              <th className='text-center'>Canvasses as a % of where we should be</th>
              <th className='text-center'>Canvasses as a % of Unique Affiliation Canvasses Target</th>
            </tr>
          </thead>
          <tbody>
            {children.map(c => {
              return (

                <tr key={c.code}>
                  {stats.type !== 'ward' ? <td><Link to={`/${c.type}/${c.code}`}>{c.name}</Link> <ResponsiblePersons persons={c.responsiblePersons} /></td> : null}
                  <td style={statsStyle(c.registration.rollAll, c.registration.target)} className='text-center'>{formatPerc(c.registration.rollAll / c.registration.target)}</td>
                  <td>
                    <span className={`badge mr-1 perc perc-pill ${statsClass(c.registration.rollSheet / c.registration.target)}`}>
                      {formatPerc(c.registration.rollSheet / c.registration.target)}
                    </span>
                  </td>
                  <td style={statsStyle(c.canvassing.current, c.canvassing.milestone)} className='text-center'>{formatPerc(c.canvassing.current / c.canvassing.milestone)}</td>
                  <td style={statsStyle(c.canvassing.current, c.canvassing.target)} className='text-center'>{formatPerc(c.canvassing.current / c.canvassing.target)}</td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr className='font-weight-bold'>
              {stats.type !== 'ward' ? (<td>Total</td>) : null}
              <td style={statsStyle(stats.registration.rollAll, stats.registration.target)} className='text-center'>{formatPerc(stats.registration.rollAll / stats.registration.target)}</td>
              <td>
                <span className={`badge mr-1 perc perc-pill ${statsClass(stats.registration.rollSheet / stats.registration.target)}`}>
                  {formatPerc(stats.registration.rollSheet / stats.registration.target)}
                </span>
              </td>
              <td style={statsStyle(stats.canvassing.current, stats.canvassing.milestone)} className='text-center'>{formatPerc(stats.canvassing.current / stats.canvassing.milestone)}</td>
              <td style={statsStyle(stats.canvassing.current, stats.canvassing.target)} className='text-center'>{formatPerc(stats.canvassing.current / stats.canvassing.target)}</td>
            </tr>
          </tfoot>
        </Table>
      </>
    )
  }
}
