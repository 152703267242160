import type { ReactChild, ReactPortal } from 'react'

export const compareStrings = (a: string, b: string): number => a < b ? -1 : (a > b ? 1 : 0)

export const formatInt = (num: number | string | null | undefined): string => {
  if (num == null) {
    return ''
  } else if (typeof num === 'number') {
    return num.toLocaleString('en-GB', { maximumFractionDigits: 0 })
  } else {
    return parseInt(num).toLocaleString('en-GB', { maximumFractionDigits: 0 })
  }
}

export const formatPerc = (num: number | null | undefined): string => {
  if (num == null) {
    return ''
  } else if (typeof num === 'number' && isNaN(num)) {
    return ''
  } else if (typeof num === 'number') {
    return (num * 100).toLocaleString('en-GB', { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + ' %'
  } else {
    return (parseFloat(num) * 100).toLocaleString('en-GB', { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + ' %'
  }
}

const percColour = (perc: number): string => {

  let backgroundColor = '#c00000' // Dark Red
  if (perc >= 100) {
    backgroundColor = '#0070c0' // Blue
  } else if (perc >= 75) {
    backgroundColor = '#70ad47' // Green
  } else if (perc >= 50) {
    backgroundColor = '#ff7f00' // Orange
  } else if (perc >= 25) {
    backgroundColor = '#ff0000' // Red
  }

  return backgroundColor
}

export const statsStyle = (achieved: number, total: number): { fontWeight: string, color: string, backgroundColor: string } => {
  const perc = Math.round((achieved / total) * 1000) / 10.0
  return { fontWeight: 'bold', color: 'white', backgroundColor: percColour(perc) }
}

export const statsStyleBorder = (achieved: number, total: number): any => {
  const perc = Math.round((achieved / total) * 1000) / 10.0
  return { fontWeight: 'bold', outlineStyle: 'solid', outlineWidth: '5px', outlineOffset: '-10px', verticalAlign: 'middle', outlineColor: percColour(perc) }
}

export const statsClass = (fraction: number): string => {
  const perc = Math.round(fraction * 1000) / 10.0

  let backgroundColor = 'perc-darkred' // Dark Red
  if (perc >= 100) {
    backgroundColor = 'perc-blue' // Blue
  } else if (perc >= 75) {
    backgroundColor = 'perc-green' // Green
  } else if (perc >= 50) {
    backgroundColor = 'perc-orange' // Orange
  } else if (perc >= 25) {
    backgroundColor = 'perc-red' // Red
  }

  return backgroundColor
}


export const firstCap = (str: string): string =>
  str.charAt(0).toUpperCase() + str.substring(1)

// see https://fettblog.eu/react-types-for-children-are-broken/
type ReactNode =
  | ReactChild
  | ReactNode[]
  | ReactPortal
  | boolean
  | null
  | undefined

export type WithChildren<T = {}> = T & { children?: ReactNode }
