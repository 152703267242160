import React from 'react'

import { Link } from 'react-router-dom'
import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, wardNamesCleanup } from '../lib/structures'
import { useRegistration } from '../data/useRegistration'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import ResponsiblePersons from './ResponsiblePersons'

import Table from 'react-bootstrap/Table'
import { Col, Row } from 'react-bootstrap'

import Map from './Map'

import { LoadingSpinner } from './spinners'
import { NoAssignedWardsMessage } from './alerts'
import { statsStyle, statsClass, formatInt, formatPerc } from '../lib/utils'

import {
  Chart as ChartJS,
  registerables
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation'

import { Line } from 'react-chartjs-2'

ChartJS.register(annotationPlugin, ...registerables)

export default function RegistrationView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = useRegistration({ type, code })

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)
    .sort((a, b) => {
      if (b.registration.target === 0 && a.registration.target === 0) return b.registration.rollAll - a.registration.rollAll
      else if (b.registration.target === 0) return -1
      else if (a.registration.target === 0) return 1
      else return (b.registration.rollAll / b.registration.target) - (a.registration.rollAll / a.registration.target)
    })

  const mapColor = (code: string): string => {
    const c = children.find(c => c.code === code)
    if (c == null) return '#ffffff'
    const color = statsStyle(c.registration.rollAll, c.registration.target).backgroundColor
    return color
  }

  const historicRollLine = stats.registration.historicRoll.map(datum => ({ x: datum.date, y: datum.count }))
  const historicTagsLine = stats.registration.historicTags.map(datum => ({ x: datum.date, y: datum.count }))
  const milestonesLine = stats.registration.milestones.map(datum => ({ x: datum.date, y: datum.count }))

  console.log(milestonesLine)

  const graphData = {
    datasets: [
      {
        label: 'Registrations per IEC',
        data: historicRollLine,
        borderColor: '#4D77FF'
      },
      {
        label: 'Tags applied* to DA Voters',
        data: historicTagsLine,
        borderColor: '#70AD47'
      }
    ]
  }

  const graphOptions = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'd LLL yyyy'
        }
      },
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      autocolors: false,
      annotation: {
        annotations: {
          target: {
            type: 'line',
            yMin: stats.registration.target,
            yMax: stats.registration.target,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            label: {
              enabled: true,
              content: 'Target'
            }
          }
        }
      }
    }
  }

  if (!stats.structureHasAssignedWards) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/registration' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='registration' />

        <NoAssignedWardsMessage type={stats.type} />
      </>
    )
  } else {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/registration' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='registration' />

        <Row>
          <Col md={6}>
            <div style={{ position: 'relative', height: '400px', width: '100%' }}>
              {/* @ts-expect-error */}
              <Line options={graphOptions} data={graphData} />

            </div>
          </Col>
          <Col md={6}>
            <Map type={type} code={code} childStructures={stats.children} childColourFn={mapColor} />
          </Col>
        </Row>

        <Table responsive striped size="sm" className='break-before stats'>
          <thead>
            <tr>
              {stats.type !== 'ward' ? <th /> : null}
              <th className='text-center'>A</th>
              <th className='text-center'>B</th>
              <th className='text-center'>C = B/A</th>
              <th className='text-center'>D</th>
              <th className='text-center'>E = D/A</th>
              <th className='text-center'>F</th>
              <th className='text-center'>G</th>
              <th className='text-center'>H = G/F</th>
              <th className='text-center'>I</th>
              <th className='text-center'>J</th>
              <th className='text-center'>K = J/I</th>
            </tr>
            <tr>
              {stats.type !== 'ward' ? <th /> : null}
              <th className='text-center'>Registration Target</th>
              <th className='text-center'>DA&nbsp;Voters confirmed as registered</th>
              <th className='text-center'>% Target Success</th>
              <th className='text-center'>DA&nbsp;Voters on sheets confirmed as registered</th>
              <th className='text-center'>% DA&nbsp;Voters on sheets confirmed as registered, out of Target</th>
              <th className='text-center'><span title="Will Register">WR</span> tagged DA&nbsp;Voters on the sheets</th>
              <th className='text-center'><span title="Will Register">WR</span> tagged DA&nbsp;Voters on the sheets that registered</th>
              <th className='text-center'>% of <span title="Will Register">WR</span> tagged DA&nbsp;Voters on the sheets that registered</th>
              <th className='text-center'><span title="Assisted to Register">ASTREG</span> tagged DA&nbsp;Voters on the sheets</th>
              <th className='text-center'><span title="Assisted to Register">ASTREG</span> tagged DA&nbsp;Voters on the sheets that registered</th>
              <th className='text-center'>% of <span title="Assisted to Register">ASTREG</span> tagged DA&nbsp;Voters on the sheets that registered</th>

            </tr>
          </thead>
          <tbody>
            {children.map(c => (
              <tr key={c.code}>
                {stats.type !== 'ward' ? <td><Link to={`/${c.type}/${c.code}/registration`}>{c.name}</Link> <ResponsiblePersons persons={c.responsiblePersons} /></td> : null}
                <td>{formatInt(c.registration.target)}</td>
                <td>{formatInt(c.registration.rollAll)}</td>
                <td className={`perc ${statsClass(c.registration.rollAll / c.registration.target)}`}>
                  {formatPerc(c.registration.rollAll / c.registration.target)}
                </td>
                <td>{formatInt(c.registration.rollSheet)}</td>
                <td>
                  <span className={`badge mr-1 perc perc-pill ${statsClass(c.registration.rollSheet / c.registration.target)}`}>
                    {formatPerc(c.registration.rollSheet / c.registration.target)}
                  </span>
                </td>
                <td>{formatInt(c.registration.tags.wrSheet)}</td>
                <td>{formatInt(c.registration.tags.wrSheetRoll)}</td>
                <td>
                  <span className={`badge mr-1 perc perc-pill ${statsClass(c.registration.tags.wrSheetRoll / c.registration.tags.wrSheet)}`}>
                    {formatPerc(c.registration.tags.wrSheetRoll / c.registration.tags.wrSheet)}
                  </span>
                </td>
                <td>{formatInt(c.registration.tags.astregSheet)}</td>
                <td>{formatInt(c.registration.tags.astregSheetRoll)}</td>
                <td>
                  <span className={`badge mr-1 perc perc-pill ${statsClass(c.registration.tags.astregSheetRoll / c.registration.tags.astregSheet)}`}>
                    {formatPerc(c.registration.tags.astregSheetRoll / c.registration.tags.astregSheet)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className='font-weight-bold'>
              {stats.type !== 'ward' ? <td>Total</td> : null}
              <td className='text-center'>{formatInt(stats.registration.target)}</td>
              <td className='text-center'>{formatInt(stats.registration.rollAll)}</td>
              <td className={`text-center perc ${statsClass(stats.registration.rollAll / stats.registration.target)}`}>
                {formatPerc(stats.registration.rollAll / stats.registration.target)}
              </td>
              <td className='text-center'>{formatInt(stats.registration.rollSheet)}</td>
              <td>
                <span className={`badge mr-1 perc perc-pill ${statsClass(stats.registration.rollSheet / stats.registration.target)}`}>
                  {formatPerc(stats.registration.rollSheet / stats.registration.target)}
                </span>
              </td>
              <td>{formatInt(stats.registration.tags.wrSheet)}</td>
              <td>{formatInt(stats.registration.tags.wrSheetRoll)}</td>
              <td>
                <span className={`badge mr-1 perc perc-pill ${statsClass(stats.registration.tags.wrSheetRoll / stats.registration.tags.wrSheet)}`}>
                  {formatPerc(stats.registration.tags.wrSheetRoll / stats.registration.tags.wrSheet)}
                </span>
              </td>
              <td>{formatInt(stats.registration.tags.astregSheet)}</td>
              <td>{formatInt(stats.registration.tags.astregSheetRoll)}</td>
              <td>
                <span className={`badge mr-1 perc perc-pill ${statsClass(stats.registration.tags.astregSheetRoll / stats.registration.tags.astregSheet)}`}>
                  {formatPerc(stats.registration.tags.astregSheetRoll / stats.registration.tags.astregSheet)}
                </span>
              </td>
            </tr>
          </tfoot>
        </Table>
        <i>WR = Will Register tag, ASTREG = Assisted to Register tag, "registered" means voter is registered correctly per IEC Green (Same Living and Registered VD)</i>
      </>
    )
  }
}
