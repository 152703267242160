import { gql, useQuery } from '@apollo/client'
import type { StructureBase } from '../lib/structures'

const query = gql`
query getUserStructures {
  userStructures {
    type code name userHasAccess userHasAdminAccess structureHasAssignedWards
  }
}
`

interface Result {
  userStructures: StructureBase[]
}

export default function useUserStructures (): StructureBase[] | undefined {
  const { error, data } = useQuery<Result>(query)
  if (error != null) throw error
  return data?.userStructures
}
