import ReactDOM from 'react-dom'
import 'leaflet/dist/leaflet.css'
import './index.css'
import ResizeObserverPolyfill from 'resize-observer-polyfill'
import App from './App'

if (typeof window !== 'undefined' && window.ResizeObserver == null) {
  window.ResizeObserver = ResizeObserverPolyfill
}

ReactDOM.render(<App />, document.getElementById('root'))
